<template>
  <header class="header">
    <div class="header-content max-container">
      <div class="header-content__nav">
        <div class="header-content__nav--img">
          <a href="/">
            <img class="header-content__img" src="../assets/images/logo2.png" alt="Rifate logo"/>
          </a>
        </div>
      </div>
      <ul class="header-content__info--list">
        <li class="header-content__info--list-item" v-for="(opcion, index) in menuOptions" :key="index">
          <router-link :to="opcion.link" >{{ opcion.title }}</router-link>
        </li>
      </ul>
      <!-- <SelectLenguage/> -->
      <div class="header-content__cta">
        <img @click="changeLanguage('en')" width="41" src="@/assets/images/english.png" alt="en">
        <img @click="changeLanguage('es')" width="41" src="@/assets/images/spanish.png" alt="es">
        <div class="header-buttons">
          <router-link v-for="(opcion, index) in signUpBtn" :key="index" :to="opcion.link"  class="btn-login secondary-button secondary-button__register">{{ opcion.title }}</router-link>
          <a href="#" class="btn-login secondary-button secondary-button__betslip" @click="openModal">{{ $t("IniciarSesion") }}</a>
        </div>
      </div>
    </div>
    <ModalComponent ref="modal" />
  </header>
</template>

<script>
  import ModalComponent from '@/components/modal.vue';
  // import { createRouter, createWebHistory } from 'vue-router';
  import { computed, reactive, watch } from 'vue';
  import { useI18n } from 'vue-i18n';
  // import SelectLenguage from './select-lenguage.vue';
  // import MenuComponent from '@/components/menu.vue'

  export default {
    
    name: "HeaderComponent",
    components: {
      ModalComponent,
      // SelectLenguage,
      // MenuComponent
    },
    watch: {
          $route(newVar){
          let pathName = computed(() =>newVar); 
          if(pathName.value != null) {
            console.log(pathName.value)
            this.detectLang(pathName.value.name);
          }
        }
      },
    methods: {
      openModal() {
        this.$refs.modal.openModal();
      },

      changeLanguage(locale) {

          if(this.$i18n.locale!= locale){
            this.$i18n.locale = locale;
            const t = this.$t;
            
            // const translatedRoutes = this.getTranslatedRoutes(t);
            // const router = createRouter({
            //   history: createWebHistory(),
            //   routes: translatedRoutes
            // });
            // this.$router.matcher = router.matcher;
            const currentRoute = this.$router.currentRoute.value;

            if (currentRoute.path !== '/') {
              const translatedPath = `/${t(`routes.${currentRoute.name}`)}`;
              this.$router.push(translatedPath);
            }
          }
          }, 
          getTranslatedRoutes(t) { 
            console.log(this.$router)
        const routes = this.$router.options.routes.map((route) => ({
          ...route,
          path: route.path !== '/' ? `/${t(`routes.${route.name}`)}` : '/',
        }));
        
        return routes;
      },
        detectLang(pathName){
          console.log(pathName);
          if(pathName.includes("langes")){
            this.$i18n.locale = "es";
            this.changeLanguage("es")
          }else if(pathName.includes("langen")){
            this.$i18n.locale = "en";
            this.changeLanguage("en")
          }else{
            console.log("default")
          }
      }
    },
    setup() {
      const { t, locale }  = useI18n();
      const menuOptions = reactive([
          {
            title      :  t("OpcionesMenu.0.title"),
            link      :  t("OpcionesMenu.0.link")
          },
          {
            title       :  t("OpcionesMenu.1.title"),
            link      :  t("OpcionesMenu.1.link")
          },
          {
            title       :  t("OpcionesMenu.2.title"),
            link      :  t("OpcionesMenu.2.link")
          },
          {
            title       :  t("OpcionesMenu.3.title"),
            link      :  t("OpcionesMenu.3.link")
          },
          /*{
            title       :  t("OpcionesMenu.4.title"),
            link      :  t("OpcionesMenu.4.link")
          },*/
      ])
      const signUpBtn = reactive ([
      {
            title      :  t("RegistroBtn.0.title"),
            link      :  t("RegistroBtn.0.link")
          },
      ])
        // Observar los cambios de idioma y actualiza los slides
            const updateSlides = () => {
          menuOptions.forEach((opcion, index) => {
          opcion.title = t(`OpcionesMenu.${index}.title`);
          opcion.link = t(`OpcionesMenu.${index}.link`);
        });
        signUpBtn.forEach((opcion, index) => {
          opcion.title = t(`RegistroBtn.${index}.title`);
          opcion.link = t(`RegistroBtn.${index}.link`);
        });
      };
      // Escuchar los cambios de idioma y actualiza los slides
      watch(locale, () => {
        updateSlides();
      });
      return {
        menuOptions,
        signUpBtn
      }
    },
  };
</script>
